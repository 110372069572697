import Image from 'next/image';

import RequestDemoButton from '@/components/RequestDemoButton';
import { cn } from '@/utils/theming';

export default function GetStarted({ className }: { className?: string }) {
  return (
    <section className={cn('bg-white', className)}>
      <div className="mx-auto max-w-screen-xl px-4 py-10 md:px-10">
        <div className="mx-auto max-w-screen-sm text-center">
          <Image
            src="/images/landing_v2/get-started-icon.png"
            alt="Lebra"
            height={204}
            width={168}
            className="mx-auto mb-6"
          />
          <h2 className="mb-4 text-2xl md:text-4xl font-semibold text-gray-900">
            Create a Culture of Connection
          </h2>
          <p className="mb-6 text-lg text-gray-500">
            Conquer workforce challenges with secure, easy-to-use and
            action-oriented AI-powered leadership solutions that save leaders
            time and facilitate stronger relationships.
          </p>
          <RequestDemoButton className="px-10 mx-auto md:max-w-[260px] h-[48px]" />
        </div>
      </div>
    </section>
  );
}
