import { Button, Dropdown } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import type { PropsWithChildren } from 'react';
import { useState } from 'react';

import RequestDemoButton from '@/components/RequestDemoButton';

export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  const toggleSection = (section: string) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <header className="fixed inset-x-0 top-0 z-20 bg-white/70 backdrop-blur-md">
      <nav className="flex flex-col md:flex-row h-16 items-center border-b border-gray-200 border-b-gray-100 lg:h-20">
        <div className="m-auto flex w-full max-w-screen-xl flex-wrap items-center justify-between md:px-4">
          <div className="flex items-center space-x-8 pl-4 md:pl-0">
            <Link href="/" className="flex items-center">
              <img
                src="/images/logo.svg"
                className="h-[20px] lg:h-[30px]"
                alt="Lebra Logo"
              />
            </Link>
            <div className="hidden lg:flex items-center space-x-8">
              <div className="rounded-lg hover:bg-gray-50">
                <Dropdown
                  label={
                    <span className="rounded-lg border-none text-base font-medium text-gray-700 transition focus:outline-none focus:ring-transparent">
                      Solutions
                    </span>
                  }
                  className="hidden lg:block text-gray-700"
                >
                  <Dropdown.Item
                    as={'div'}
                    className="!px-2 hover:bg-blue-25 h-8 lg:h-14"
                  >
                    <HeaderNavigationItem
                      href="/solution/leadership"
                      heading="Leadership Platform"
                      description="Recognize, engage, and retain talent"
                      className="hidden lg:block w-full"
                      icon={
                        <Image
                          src="/images/landing_v3/navBar/flag-icon.svg"
                          alt="Office Building Icon"
                          width={16}
                          height={16}
                        />
                      }
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={'div'}
                    className="!px-2 hover:bg-blue-25 h-8 lg:h-14"
                  >
                    <HeaderNavigationItem
                      href="/solution/org-ai"
                      heading="Org AI"
                      description="AI built for leadership"
                      className="hidden py-2 lg:block w-full"
                      icon={
                        <Image
                          src="/images/landing_v3/navBar/office-building-icon.svg"
                          alt="Office Building Icon"
                          width={16}
                          height={16}
                        />
                      }
                    />
                  </Dropdown.Item>
                </Dropdown>
              </div>
              <div className="rounded-lg hover:bg-gray-50">
                <Dropdown
                  label={
                    <span className="rounded-lg border-none text-base font-medium text-gray-700 transition focus:outline-none focus:ring-transparent">
                      Industries
                    </span>
                  }
                  className="hidden lg:block text-gray-700"
                >
                  <Dropdown.Item
                    as={'div'}
                    className="!px-2 hover:bg-blue-25 h-6 lg:h-12"
                  >
                    <HeaderNavigationItem
                      href="/industries/education"
                      heading="Education"
                      className="hidden py-2 lg:block w-full"
                      icon={
                        <Image
                          src="/images/landing_v3/navBar/education-icon.svg"
                          alt="Education Icon"
                          width={16}
                          height={16}
                        />
                      }
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={'div'}
                    className="!px-2 hover:bg-blue-25 h-6 lg:h-12"
                  >
                    <HeaderNavigationItem
                      href="/industries/healthcare"
                      heading="Healthcare"
                      className="hidden py-2 lg:block w-full"
                      icon={
                        <Image
                          src="/images/landing_v3/navBar/healthcare-icon.svg"
                          alt="Healthcare Icon"
                          width={16}
                          height={16}
                        />
                      }
                    />
                  </Dropdown.Item>
                </Dropdown>
              </div>
              <div className="rounded-lg hover:bg-gray-50">
                <Dropdown
                  label={
                    <span className="rounded-lg border-none text-base font-medium text-gray-700 transition focus:outline-none focus:ring-transparent">
                      Company
                    </span>
                  }
                  className="hidden lg:block text-gray-700"
                >
                  <Dropdown.Item
                    as={'div'}
                    className="!px-2 hover:bg-blue-25 h-10"
                  >
                    <HeaderNavigationItem
                      href="/about-us"
                      heading="About Us"
                      className="hidden lg:block w-full"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={'div'}
                    className="!px-2 hover:bg-blue-25 h-10"
                  >
                    <HeaderNavigationItem
                      href="/security-and-privacy-policy"
                      heading="Security & Privacy"
                      className="hidden lg:block w-full"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={'div'}
                    className="!px-2 hover:bg-blue-25 h-10"
                  >
                    <HeaderNavigationItem
                      href="/blog"
                      heading="Blog"
                      className="hidden lg:block w-full"
                    />
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center space-x-2 lg:order-2 lg:space-x-3">
              <Link href="/auth/login">
                <button className="h-8 rounded-lg border-none px-3 text-sm font-medium text-gray-700 transition hover:bg-blue-50 hover:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-300 lg:h-10 lg:text-base">
                  Sign in
                </button>
              </Link>
              <RequestDemoButton
                showArrow={false}
                className="h-8 text-sm lg:h-10 lg:text-base"
              />
            </div>
            <Button
              className="lg:hidden"
              onClick={() => setIsMobileMenuOpen((prev) => !prev)}
            >
              {isMobileMenuOpen ? (
                <Image
                  src="/images/landing_v3/cross.svg"
                  alt="Mobile Menu"
                  width={24}
                  height={24}
                />
              ) : (
                <Image
                  src="/images/landing_v3/mobile-menu.svg"
                  alt="Mobile Menu"
                  width={24}
                  height={24}
                />
              )}
            </Button>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="absolute h-screen w-full top-16 bg-white z-[1000] lg:hidden">
            <div className="p-4">
              <div className="space-y-4">
                <div>
                  <button
                    className="flex items-center justify-between w-full py-2"
                    onClick={() => toggleSection('solutions')}
                  >
                    <span className="text-lg font-medium">Solutions</span>
                    <svg
                      className={`w-5 h-5 transition-transform ${
                        expandedSection === 'solutions' ? 'rotate-180' : ''
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {expandedSection === 'solutions' && (
                    <div className="mt-2 space-y-2">
                      <MobileNavigationItem
                        href="/solution/leadership"
                        icon="/images/landing_v3/navBar/flag-icon.svg"
                        heading="Leadership"
                      />
                      <MobileNavigationItem
                        href="/solution/org-ai"
                        icon="/images/landing_v3/navBar/office-building-icon.svg"
                        heading="OrgAi"
                      />
                    </div>
                  )}
                </div>

                {/* Industries Section */}
                <div>
                  <button
                    className="flex items-center justify-between w-full py-2"
                    onClick={() => toggleSection('industries')}
                  >
                    <span className="text-lg font-medium">Industries</span>
                    <svg
                      className={`w-5 h-5 transition-transform ${
                        expandedSection === 'industries' ? 'rotate-180' : ''
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {expandedSection === 'industries' && (
                    <div className="mt-2 space-y-2">
                      <MobileNavigationItem
                        href="/industries/education"
                        icon="/images/landing_v3/navBar/education-icon.svg"
                        heading="Education"
                      />
                      <MobileNavigationItem
                        href="/industries/healthcare"
                        icon="/images/landing_v3/navBar/healthcare-icon.svg"
                        heading="Healthcare"
                      />
                    </div>
                  )}
                </div>

                {/* Company Section */}
                <div>
                  <button
                    className="flex items-center justify-between w-full py-2"
                    onClick={() => toggleSection('company')}
                  >
                    <span className="text-lg font-medium">Company</span>
                    <svg
                      className={`w-5 h-5 transition-transform ${
                        expandedSection === 'company' ? 'rotate-180' : ''
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {expandedSection === 'company' && (
                    <div className="mt-2 space-y-2">
                      <Link
                        href="/about-us"
                        className="block py-2 text-sm text-gray-900"
                      >
                        About Us
                      </Link>
                      <Link
                        href="/security-and-privacy-policy"
                        className="block py-2 text-sm text-gray-900"
                      >
                        Security & Privacy
                      </Link>
                      <Link
                        href="/blog"
                        className="block py-2 text-sm text-gray-900"
                      >
                        Blog
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}

function HeaderNavigationItem({
  className,
  href,
  heading,
  description,
  icon,
}: PropsWithChildren<{
  className?: string;
  href: string;
  heading: string;
  description?: string;
  icon?: React.ReactNode;
}>) {
  return (
    <Link href={href} className={className}>
      <div className="flex items-center gap-3 text-base font-medium text-gray-700 rounded-lg border-none transition hover:text-blue-700 focus:outline-none">
        {icon && <div className="rounded-lg bg-blue-gradient p-2">{icon}</div>}
        <div className="flex flex-col">
          <h5 className="text-sm font-medium text-gray-900 mr-auto">
            {heading}
          </h5>
          {description && (
            <p className="text-sm text-gray-500 mr-auto">{description}</p>
          )}
        </div>
      </div>
    </Link>
  );
}

function MobileNavigationItem({
  href,
  icon,
  heading,
}: {
  href: string;
  icon: string;
  heading: string;
}) {
  return (
    <Link href={href} className="flex items-center space-x-3 py-2">
      <div className="rounded-lg">
        <Image src={icon} alt={`${heading} Icon`} width={16} height={16} />
      </div>
      <span className="font-medium text-gray-900 text-base">{heading}</span>
    </Link>
  );
}
