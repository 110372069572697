import Image from 'next/image';
import Link from 'next/link';

export default function Footer() {
  return (
    <footer className="mx-6 mb-6 rounded-[20px] md:pt-10">
      <div className="mx-auto grid max-w-7xl grid-cols-2 gap-6 px-4 pb-8 pt-10 lg:grid-cols-5 lg:gap-0 lg:px-0">
        <Link href="/" className="col-span-2 mb-8 lg:col-span-1 lg:mb-0">
          <img src="/images/logo.svg" className="h-7" alt="Lebra Logo" />
          <p className="font-semibold text-gray-900 mt-4">Amplify Humanity</p>
        </Link>
        <FooterColumn
          title="Solutions"
          items={[
            {
              text: 'Leadership',
              href: '/solution/leadership',
            },
            {
              text: 'Org AI',
              href: '/solution/org-ai',
            },
          ]}
        />
        <FooterColumn
          title="Industries"
          items={[
            {
              text: 'Education',
              href: '/industries/education',
            },
            {
              text: 'Healthcare',
              href: '/industries/healthcare',
            },
          ]}
        />
        <FooterColumn
          title="Company"
          items={[
            {
              text: 'About Us',
              href: '/about-us',
            },
            {
              text: 'Security and Privacy',
              href: '/security-and-privacy-policy',
            },
            {
              text: 'Blog',
              href: '/blog',
            },
          ]}
        />
        <div>
          <p className="text-base font-medium text-gray-900">Social</p>
          <div className="mt-2 flex gap-2">
            <Link href="https://www.youtube.com/@lebrahq" target="_blank">
              <Image
                src="/images/landing_v3/social/youtube.svg"
                alt="Youtube"
                width={20}
                height={20}
              />
            </Link>
            <Link href="https://x.com/lebrahq" target="_blank">
              <Image
                src="/images/landing_v3/social/x.svg"
                alt="X"
                width={20}
                height={20}
              />
            </Link>
            <Link
              href="https://www.linkedin.com/company/lebrahq"
              target="_blank"
            >
              <Image
                src="/images/landing_v3/social/linkedin.svg"
                alt="Linkedin"
                width={20}
                height={20}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="mx-auto flex flex-col gap-2 md:flex-row max-w-7xl justify-between py-8 px-4 md:px-0 text-xs text-gray-500 dark:text-gray-400">
        <div>
          {new Date().getFullYear()} © Lebra Technologies Inc. All Rights
          Reserved.
        </div>
        <div className="flex gap-2 md:flex-row">
          <Link href="/privacy-policy">
            <p className="text-xs text-gray-500 hover:underline">
              Privacy Policy
            </p>
          </Link>
          <Link href="/terms-of-use">
            <p className="text-xs text-gray-500 hover:underline">
              Terms of Use
            </p>
          </Link>
        </div>
      </div>
    </footer>
  );
}

const FooterColumn = ({
  title,
  items,
}: {
  title: string;
  items: { text: string; href: string }[];
}) => (
  <div>
    <p className="text-base font-medium text-gray-900">{title}</p>
    <div className="mt-2 flex flex-col space-y-1">
      {items.map((item) => {
        return (
          <Link
            key={item.text}
            href={item.href}
            className="text-base text-gray-700 hover:text-gray-500 hover:underline"
          >
            {item.text}
          </Link>
        );
      })}
    </div>
  </div>
);
